import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeRegion, changeState } from "../../../redux/dashboard/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import topology from "@highcharts/map-collection/countries/us/custom/us-all-mainland.topo.json";
import { stateColors } from "../../../static/data/stateDict";
import { regionData, regionDict } from "../../../static/data/regionDict";

highchartsMap(Highcharts);

function USMap({region, USstate, msaData}) {
    const dispatch = useDispatch();
    const chartComponentRef = useRef(null);
    const [mapData, setMapData] = useState(topology);
    const [options, setOptions] = useState({
        chart: {
            map: mapData,
            height: '280',
            margin: [25, 25, 25, 25],
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
        },
        title: {
            text: ''
        },
        accessibility: {
            series: {
                descriptionFormat: '{series.name} region with {series.points.length} properties.'
            },
            point: {
                valueDescriptionFormat: '{point.name}.'
            }
        },
        legend: {
            enabled: false
        },
        reflow: true,
        plotOptions: {
            map: {
                allAreas: false,
                joinBy: ['hc-a2', 'code'],
                tooltip: {
                    headerFormat: '',
                    pointFormat: '<b>{series.name}</b>'
                },
                cursor: 'pointer',
                point: {
                    events: {
                        click: function () {
                            dispatch(changeRegion({ region: this.series.name }));
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 1500
                },
                chartOptions: {
                    chart: {
                        height: 240
                    }
                }
            }]
        }
    })
    
    const filterMapData = (region) => {
        const filteredGeometries = topology.objects.default.geometries.filter(geometry => regionDict[region].includes(geometry.properties['hc-a2'].toUpperCase()))
        
        const croppedTopology = {
            ...topology,
            objects: {
                default: {
                    type: 'GeometryCollection',
                    geometries: filteredGeometries
                }
            }
        };

        return croppedTopology
    };
    
    const filterStateMapData = (state) => {
        const filteredGeometries = topology.objects.default.geometries.filter(geometry => state.includes(geometry.properties['hc-a2'].toUpperCase()))
        
        const croppedTopology = {
            ...topology,
            objects: {
                default: {
                    type: 'GeometryCollection',
                    geometries: filteredGeometries
                }
            }
        };

        return croppedTopology
    };

    
    useEffect(() => {
        const msaPoints = Object.values(msaData).map(data => ({name: data.display_name, lat: data.latitude, lon: data.longitude}));

        if (region !== '' && USstate === '') {
            const filteredData = filterMapData(region);
            setOptions(prevOptions => ({
                ...prevOptions,
                chart: {
                    ...prevOptions.chart,
                    map: filteredData
                },
                tooltip: {
                    enabled: true
                },
                plotOptions: {
                    map: {
                        ...prevOptions.plotOptions.map,
                        cursor: 'pointer',
                        states: {
                            hover: {
                                enabled: true
                            }
                        },
                        point: {
                            events: {
                                click: function () {
                                    dispatch(changeState(this.properties["hc-a2"]));
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '',
                            pointFormat: '<b>{point.name}</b>'
                        }
                    }
                },
                series: [
                    ...Object.keys(regionData).map(regionKey => ({
                        name: regionKey,
                        data: regionData[regionKey].states.map(code => ({
                            code,
                            color: stateColors[code]
                        })),
                        colorByPoint: true
                    })),
                    {
                        type: 'mappoint',
                        name: 'MSA',
                        accessibility: {
                            point: {
                                valueDescriptionFormat: '{point.name}'
                            }
                        },
                        color: 'black',
                        data: msaPoints,
                        tooltip: {
                            headerFormat: '',
                            pointFormat: '<b>{point.name}</b>'
                        },
                        dataLabels: {
                            enabled: false
                        }
                    }
                ]
            }));
        } else if (region === '' && USstate !== '') {
            const filteredData = filterStateMapData(USstate);
            setOptions(prevOptions => ({
                ...prevOptions,
                chart: {
                    ...prevOptions.chart,
                    map: filteredData
                },
                plotOptions: {
                    map: {
                        ...prevOptions.plotOptions.map,
                        cursor: 'default',
                        tooltip: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                },
                series: [
                    ...Object.keys(regionData).map(regionKey => ({
                        name: regionKey,
                        data: regionData[regionKey].states.map(code => ({
                            code,
                            color: stateColors[code]
                        })),
                        colorByPoint: true
                    })),
                    {
                        type: 'mappoint',
                        name: 'MSA',
                        accessibility: {
                            point: {
                                valueDescriptionFormat: '{point.name}'
                            }
                        },
                        color: 'black',
                        data: msaPoints,
                        tooltip: {
                            pointFormat: '<b>{point.name}</b>'
                        },
                        dataLabels: {
                            enabled: false
                        }
                    }
                ]
            }));
        } else {
            setMapData(topology);
            setOptions(prevOptions => ({
                ...prevOptions,
                chart: {
                    ...prevOptions.chart,
                    map: topology
                },
                series: Object.keys(regionData).map(regionKey => ({
                    name: regionKey,
                    data: regionData[regionKey].states.map(code => ({ code })),
                    color: regionData[regionKey].color,
                    colorByPoint: false
                })),
                plotOptions: {
                    map: {
                        ...prevOptions.plotOptions.map,
                        point: {
                            events: {
                                click: function () {
                                    dispatch(changeRegion({ region: this.series.name }));
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '',
                            pointFormat: '<b>{series.name}</b>'
                        },
                        cursor: 'pointer',
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                }
            }));
        }
    }, [region, USstate, msaData]);

    if (chartComponentRef.current) {
        chartComponentRef.current.chart.update(options);
    }

    return (
        <div id="portfolio-map-container" className="w-[40%]">
            <HighchartsReact
                constructorType ={'mapChart'}
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
            />
        </div>
    )
}

export default USMap
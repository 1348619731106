import useCheckToken from "../../hooks/useCheckToken";
import NavHeader from "../NavHeader";
import PortfolioWidget from "./PortfolioWidget/PortfolioWidget";
import ActionItemWidget from "./ActionItemWidget/ActionItemWidget";
import ApplianceLifeWidget from "./ApplianceLifeWidget/ApplianceLifeWidget";
import DiscrepanciesWidget from "./DiscrepanciesWidget/DiscrepanciesWidget";
import ExpensesHomeCatalogWidget from "./ExpensesWidget/ExpensesHomeCatalogWidget";

function WidgetDashboard() {
    useCheckToken();
    
    return (
        <>
            <NavHeader header="Dashboard" />
            <div className="w-full py-10 pt-24 overflow-auto">
                <div className="flex">
                    <PortfolioWidget />
                    <ApplianceLifeWidget />
                </div>
                <div className="flex">
                    <ActionItemWidget />
                    <DiscrepanciesWidget />
                </div>
                <div className="flex">
                    <ExpensesHomeCatalogWidget />
                </div>
            </div>
        </>
    )
}

export default WidgetDashboard
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleGetError } from '../../hooks/ErrorHandling';
import { handleInvitationError } from '../../hooks/ErrorHandling';
import { FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordChecklist from "react-password-checklist";

function NewPassword({invitationId}) {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [strongPassword, setStrongPassword] = useState(false);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const navigate = useNavigate();

    // Toggle show/hide password

    const handleClickShowPassword = (password) => {
        password === 'create' ? setShowNewPassword((show) => !show) : setShowConfirmPassword((show) => !show)
    };

    // Execute password validation functions

    const handleChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);
      };

    const handlePasswordMatch = (e) => {
        const pass = e.target.value;
        setConfirmPassword(pass);
    }

    // Submit password once all fields are validated

    const handleSubmit = () => {
        fetch(`${process.env.REACT_APP_API_URL}/invitations/${invitationId}/use`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({password: confirmPassword})
          })
            .then((response) => {
                if (!response.ok) {
                    const errorMessage = handleInvitationError(response.status);
                    toast.error(errorMessage);
                } else {
                    toast.success("Password update successful.")
                    navigate('/')
                }
            })
            .catch((error) => {
                toast.error(error)
            })
    }

    return (
        <div className="w-[432px] flex flex-col items-center">
            <p className="text-center mb-3">Awesome! Now let’s create a strong password. Your username will be your email address. </p>
            <div className="flex flex-col items-center">
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="create-new-password">Create New Password</InputLabel>
                    <OutlinedInput
                        id="create-new-password"
                        onChange={handleChange}
                        type={showNewPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('create')}
                            edge="end"
                            >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Create New Password"
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="confirm-new-password">Confirm New Password</InputLabel>
                    <OutlinedInput
                        id="confirm-new-password"
                        onChange={handlePasswordMatch}
                        type={showConfirmPassword ? 'text' : 'password'}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword('confirm')}
                            edge="end"
                            >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Confirm New Password"
                    />
                </FormControl>
            </div>
            <PasswordChecklist
				rules={["minLength","specialChar","number","match"]}
				minLength={8}
				value={newPassword}
				valueAgain={confirmPassword}
				onChange={(isValid) => setStrongPassword(isValid)}
                className='password-checklist'
                iconSize={12}
			/>
            {strongPassword
                ?
                <button onClick={handleSubmit} className="w-4/5 mt-7 mb-7 light-blue-button">Enter</button>
                :
                <button className="w-4/5 mt-7 mb-7 disabled-button">Enter</button>
            }
        </div>
    )
}

export default NewPassword
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {toast} from "react-toastify";
import {handleGetError} from "../../../hooks/ErrorHandling";
import ExpensesLineChart from './ExpensesLineChart';
import ExpensesPieChart from './ExpensesPieChart';
import CardEmptyData from '../CardEmptyData';

function ExpensesHomeCatalogWidget() {
    const today = new Date();
    const {region, USstate} = useSelector(state => state.dashboard);
    const {token} = useSelector((state) => state.auth);
    const organizationId = useSelector(state => state.auth.user.organization.id);
    const [yearSelected, setYearSelected] = useState(today.getFullYear());
    const [listOfYears, setListOfYears] = useState(null);
    const [expensesData, setExpensesData] = useState(null);
    const [lineChartData, setLineChartData] = useState(null);
    const [pieChartData, setPieChartData] = useState(null);

    const colorCode = {
        'Appliances': '#5D89DF',
        'Roof': '#7B47E9',
        'Electrical': '#EE60E0',
        'Plumbing': '#FD2A2A',
        'Structural': '#FE7135',
        'Interior': '#EECA34',
        'Exterior': '#6975FF',
        'Surroundings': '#64BDC6',
        'Other': '#084FD7',
    }

    // Fetch Expenses - Home Catalog data

    const getExpensesHomeCatalogData = async (organizationId, token, region, state) => {
        let url = `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/expenses/catalog`;
    
        if (region !== '') {
            url += `?region=${region}`;
        }
    
        if (state !== '') {
            url += `?state=${state}`;
        }
    
        try {
            const response = await fetch(url, {
                headers: { "Authorization": `Bearer ${token}` }
            })
    
            if (!response.ok) {
                const errorMessage = handleGetError(response.status);
                toast.error(errorMessage);
                return;
            }
            
            const data = await response.json();

            if (data.expenses && Object.keys(data.expenses).length > 0) {
                setListOfYears(Object.keys(data.expenses));
            }

            setExpensesData(data);
        } catch (error) {
            toast.error('Internal Server Error');
        }
    }

    useEffect(() => {
        getExpensesHomeCatalogData(organizationId, token, region, USstate);
    }, [region, USstate])

    useEffect(() => {
        if (expensesData) {
            formatExpensesData(expensesData);
        }
    }, [expensesData, yearSelected])

    const formatExpensesData = (data) => {
        let applianceMonthlySum;
        let applianceYearSum;
        let applianceTotalSum;
        let systemsData;
        let systemsYearSum;
        let systemsTotalSum = null;

        const appliances = data.expenses[yearSelected]?.appliances || null;
        const systems = data.expenses[yearSelected]?.systems || null;

        if (appliances) {
            applianceMonthlySum = calculateApplianceMonthlySum(appliances);
            applianceTotalSum = calculateTotalExpenses(applianceMonthlySum[0].data);
            applianceYearSum = applianceTotalSum > 0 ? [{name: 'Appliances', y: applianceTotalSum, color: colorCode['Appliances']}] : null;
        }

        if (systems) {
            systemsData = formatSystemsData(systems);
            systemsYearSum = systemsData.map(system => {
                const systemSum = calculateTotalExpenses(system.data);
                systemsTotalSum = systemSum > 0 ? systemSum : null;
                return {name: system.name, y: systemSum, color: colorCode[system.name]}
            })
        }

        if (applianceYearSum && systemsTotalSum) {
            const lineChartSeries = applianceMonthlySum.concat(systemsData);
            const pieChartSeries = applianceYearSum.concat(systemsYearSum);
            setLineChartData(lineChartSeries);
            setPieChartData(pieChartSeries);
        } else if (applianceYearSum && !systemsTotalSum) {
            setLineChartData(applianceMonthlySum);
            setPieChartData(applianceYearSum);
        } else if (systemsTotalSum && !applianceYearSum) {
            setLineChartData(systemsData);
            setPieChartData(systemsYearSum);
        } else {
            setLineChartData(null);
            setPieChartData(null);
        }
    }

    // Helper function to get appliances total cost per month

    const calculateApplianceMonthlySum = (appliances) => {
        const sumArray = Array(12).fill(0);

        for (let i = 0; i < 12; i++) {
            const indexSum = Object.values(appliances).reduce((acc, array) => {
                if (array[i] !== null) {
                    acc = acc === null ? parseFloat(array[i]) : acc + parseFloat(array[i]);
                }
                return acc;
            }, null)
            sumArray[i] = indexSum;
        }

        return [{name: 'Appliances', data: sumArray, color: colorCode['Appliances']}];
    }

    // Ensure systems data has no strings

    const formatSystemsData = (systemExpenses) => {
        const convertedExpenses = Object.entries(systemExpenses).reduce((acc, [key, array]) => {
            const modifiedArray = array.map(value => value !== null ? parseFloat(value) : value);
            acc.push({name: key, data: modifiedArray, color: colorCode[key]});
            return acc;
        }, []);

        return convertedExpenses;
    }

    // Reducer function

    const calculateTotalExpenses = (array) => {
        return array.reduce((acc, value) => value ? acc += value : acc, 0)
    }

    return null

    return (
        <div className="mx-5 mt-5 p-5 w-[48.7%] h-[400px] bg-white rounded-lg shadow overflow-auto">
            <div className="flex items-center gap-2">
                <h2 className="title-4 mb-5">Expenses - Home Catalog</h2>
            </div>
            {lineChartData && pieChartData 
                ?
                <div className="flex">
                    <div className="w-1/2">
                        <ExpensesLineChart lineChartData={lineChartData} />
                        <div className="flex justify-end gap-3">
                            {listOfYears.map((year, index) => (
                                <div key={index} onClick={() => setYearSelected(parseInt(year))} className={`rounded-md border-2 border-data-d-blue px-3 py-1 ${year == yearSelected ? 'bg-data-d-blue text-data-lt-blue' : 'bg-data-lt-blue text-data-d-blue hover:bg-data-d-blue hover:text-data-lt-blue hover:cursor-pointer'}`}>{year}</div>
                            ))}
                        </div>
                    </div>
                    <div className="w-1/2">
                        <ExpensesPieChart pieChartData={pieChartData} />
                    </div>
                </div>
                :
                <CardEmptyData />
            }
            
        </div>
    )
}

export default ExpensesHomeCatalogWidget
import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import {handleGetError} from "../../../hooks/ErrorHandling";
import dashboardActions from "../../../redux/dashboard/dashboardActions";
import ApplianceSystemSelector from '../ApplianceSystemSelector';
import ActionItemBarChart from './ActionItemBarChart';

function ActionItemWidget() {
    const dispatch = useDispatch();
    const organizationId = useSelector(state => state.auth.user.organization.id);
    const token = useSelector(state => state.auth.token);
    const {region, USstate, applianceNames, systemNames} = useSelector(state => state.dashboard);
    const [applianceArray, setApplianceArray] = useState([]);
    const [systemArray, setSystemArray] = useState([]);
    const [seriesData, setSeriesData] = useState({data: [], highestValue: 0});

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        if (value.includes("appliances") || value.includes("home_catalog_all")) {
            // If all appliances are selected, deselect them
            const areAllSelected = applianceNames.every(item => itemsArray.includes(item));
            
            if (areAllSelected) {
                setApplianceArray(applianceArray.filter(item => !applianceNames.includes(item)));
            } else {
                // Otherwise, select all appliances
                const newSelection = [...applianceArray, ...applianceNames.filter(item => !applianceArray.includes(item))];
                setApplianceArray(newSelection);
            }
        } else {
            // Handle regular selection
            const newAppliances = value.filter(v => applianceNames.includes(v));
            setApplianceArray(newAppliances);
        }

        if (value.includes("systems") || value.includes("home_catalog_all")) {
            // If all systems are selected, deselect them
            const areAllSelected = systemNames.every(item => itemsArray.includes(item));
            
            if (areAllSelected) {
                setSystemArray(systemArray.filter(item => !systemNames.includes(item)));
            } else {
                // Otherwise, select all systems
                const newSelection = [...systemArray, ...systemNames.filter(item => !systemArray.includes(item))];
                setSystemArray(newSelection);
            }
        } else {
            // Handle regular selection
            const newsystems = value.filter(v => systemNames.includes(v));
            setSystemArray(newsystems);
        }
    };

    // Fetch list of appliances and systems for select dropdown
    useEffect(() => {
        dispatch(dashboardActions.getApplianceSystemNames(organizationId, token, region, USstate));
    }, [region, USstate]);

    // Render list of appliances and systems for select dropdown
    useEffect(() => {
        setApplianceArray(applianceNames);
        setSystemArray(systemNames);
    }, [region, USstate, applianceNames, systemNames]);

    const itemsArray = applianceArray.concat(systemArray);

    // Construct query parameters to send to POST request that fetches action item data
    // When applianceArray or systemArray changes, we re-fetch the data
    const constructQueryParams = (region, USstate, appliances, systems) => {
        const queryParams = {
            appliances: [],
            systems: [],
            regions: [],
            states: []
        }

        if (appliances) {
            queryParams.appliances = appliances;
        }

        if (systems) {
            queryParams.systems = systems;
        }

        if (region === '' && USstate === '') {
            queryParams.regions = ["WEST", "MIDWEST", "SOUTHWEST", "NORTHEAST", "SOUTHEAST"];
        }

        if (region !== '') {
            queryParams.regions.push(region);
        }

        if (USstate !== '') {
            queryParams.states.push(USstate);
        }

        return queryParams;
    }

    // Fetch data to render on action item chart
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/action_items/count`,
                    {
                        method: "POST",
                        headers: {Authorization: `Bearer ${token}`, "Content-Type": "application/json"},
                        body: JSON.stringify(constructQueryParams(region, USstate, applianceArray, systemArray))
                    }
                );

                if (!response.ok) {
                    const errorMessage = handleGetError(response.status); // Consider more specific error handling
                    toast.error(errorMessage);
                    return;
                }

                const data = await response.json();

                const categoryTotals = data[0].data.map((_, colIndex) =>
                    data.reduce((sum, series) => sum + series.data[colIndex], 0)
                );

                const highestValue = Math.max(...categoryTotals);

                setSeriesData({
                    data,
                    highestValue
                });
            } catch (error) {
                toast.error('Internal Server Error'); // Consider a more specific error message
            }
        };

        fetchData();
    }, [region, USstate, applianceArray, systemArray]);

    return (
        <div id="action-item-widget" className="ml-5 mt-5 pr-5 pt-5 w-1/2 bg-white rounded-lg shadow overflow-auto">
            <div className="flex items-center gap-2">
                <h2 className="title-4 ml-5">Action Items</h2>
                <ApplianceSystemSelector
                    itemsArray={itemsArray}
                    handleChange={handleChange}
                    applianceNames={applianceNames}
                    systemNames={systemNames}
                />
            </div>
            <ActionItemBarChart 
                seriesData={seriesData}
                applianceArray={applianceArray}
                systemArray={systemArray}
            />
        </div>
    )
}

export default ActionItemWidget
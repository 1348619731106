import React, { useRef } from 'react';
import DiscrepanciesChart from './DiscrepanciesChart';

function DiscrepanciesWidget() {
    const divRef = useRef(null);

    return (
        <div id="discrepancies-widget" className="mx-5 mt-5 p-5 w-1/2 bg-white rounded-lg shadow overflow-auto">
            <div className="flex items-center gap-2">
                <h2 className="title-4 mb-5">Data Discrepancies</h2>
            </div>
            <div id="discrepancies-chart-container" className="h-[280px] overflow-auto" ref={divRef}>
                <DiscrepanciesChart />
            </div>
        </div>
    )
}

export default DiscrepanciesWidget